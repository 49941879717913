import React from 'react';
import styled from 'styled-components';
import { AltStyledLink, AltStyledA } from '../components/styled-link';

const Separator = styled.small`
  color: ${props => props.theme.breadcrumbSeparatorTextColor}
`;

const SmallAltLink = styled(AltStyledLink).attrs({className: 'small'})``;
const SmallAltA = styled(AltStyledA).attrs({className: 'small'})``;

const BreadcrumbLink = (props) => (
  <>
    <SmallAltLink {...props}/>
    <Separator> / </Separator>
  </>
);

const BreadcrumbA = (props) => (
  <>
    <SmallAltA {...props}/>
    <Separator> / </Separator>
  </>
);

const Breadcrumb = ({ className, children }) => (
  <nav className={className}>
    <Separator>/ </Separator>
    { children }
  </nav>
);

export { BreadcrumbLink, BreadcrumbA, Breadcrumb };
