import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Summary = styled.div.attrs({className: 'small'})`
`;

const Line = styled.p`
  position: relative;
  margin: 0;

  &::before {
    content: '\00a0';
    white-space: pre;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 33%;
    height: 2px;
    background: ${props => props.theme.blogSummaryLineColor};
  }
`;

const Excerpt = styled.p`
  color: ${props => props.theme.blogSummaryTextColor};
  margin-bottom: 0;
`;

const BlogSummary = ({ author, date, timeToRead, summary }) => {
    const blogMoment = moment(date);
    return (
        <Summary>
            {author}<br/>
            <time dateTime={blogMoment.format('YYYY-MM-DD')}>
              {blogMoment.format('D MMM YYYY')}
            </time>
            <span> / {timeToRead} min read</span>
            <Line/>
            <Excerpt>{summary}</Excerpt>
        </Summary>
    );
}

export default BlogSummary;