import React from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { LayoutRow, MainColumn } from '../utils/layout-row';
import FlexRow from '../utils/flex-row';
import media from '../utils/media';
import Page from '../components/page';
import Section from '../components/section';
import Markdown from '../components/markdown';
import { BreadcrumbLink, Breadcrumb } from '../components/breadcrumb';
import BlogCard from '../components/blog-card';
import BlogSummary from '../components/blog-summary';
import { BlogFilter } from '../components/blog-filter';
import SubscribeFormIntro from '../components/subscribe-form-intro';
import SubscribeForm from '../components/subscribe-form';

const RelativeMainColoumn = styled(MainColumn)`
    position: relative;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
    position: absolute;
    bottom: 100%;
`;

const StyledMarkdown = styled(Markdown)`
    p, figure {
      max-width: 650px;
    }
`;

const OneThirdColumn = styled.div`
  width: 100%;

  ${media.desk`
    width: 33.3333%;
  `}
`;

const RelatedPostsHeading = styled.small`
    color: ${props => props.theme.blogRelatedHeadingColor};
`;

const RelatedPosts = ({relatedPostEdges}) => {
  return (
    <Section as="aside">
        <LayoutRow>
          <MainColumn>
            <RelatedPostsHeading>other articles:</RelatedPostsHeading>
          </MainColumn>
        </LayoutRow>
        <FlexRow outerGap={true}>
          {
            relatedPostEdges.map(e =>
              <OneThirdColumn key={`${e.node.frontmatter.date}.${e.node.frontmatter.title}`}>
                <BlogCard
                  slug={e.node.fields.slug}
                  title={e.node.frontmatter.title}
                  author={e.node.frontmatter.author}
                  date={e.node.frontmatter.date}
                  timeToRead={e.node.timeToRead}
                  summary={e.node.frontmatter.summary}/>
              </OneThirdColumn>
            )
          }
        </FlexRow>
      </Section>
  );
};

const BlogPost = ({ data, location, navigate }) => {
  const post = data.post;
  return (
    <Page location={location}>
      <main>
        <Helmet>
          <title>{post.frontmatter.title}</title>
          <meta
            name="description"
            content={post.frontmatter.summary}
          />
        </Helmet>
        <LayoutRow>
            <RelativeMainColoumn>
              <StyledBreadcrumb>
                <BreadcrumbLink to="/news">news</BreadcrumbLink>
              </StyledBreadcrumb>
            </RelativeMainColoumn>
        </LayoutRow>
        <Section as="article">
          <LayoutRow>
            <MainColumn>
              <Section gapMultiplier={0.5}>
                <h1>{post.frontmatter.title}</h1>
                <BlogSummary
                  author={post.frontmatter.author}
                  date={post.frontmatter.date}
                  timeToRead={post.timeToRead}
                  summary={post.frontmatter.summary}/>
              </Section>
            </MainColumn>
          </LayoutRow>
          <LayoutRow>
          </LayoutRow>
          <LayoutRow>
            <MainColumn>
              <StyledMarkdown html={post.html} />
            </MainColumn>
          </LayoutRow>
        </Section>
        <Section as="aside">
          <LayoutRow>
            <MainColumn>
              <BlogFilter
                label="topics:"
                search={location.search}
                navigate={navigate}
                tags={post.frontmatter.tags}
                authors={[]}
              />
            </MainColumn>
          </LayoutRow>
        </Section>
        <Section as="aside">
          <LayoutRow>
            <MainColumn>
              <SubscribeFormIntro />
              <SubscribeForm />
            </MainColumn>
          </LayoutRow>
        </Section>
        {
          data.relatedPosts
          ? <RelatedPosts relatedPostEdges={data.relatedPosts.edges}/>
          : ''
        }
      </main>
    </Page>
  );
};

export const query = graphql`
  #query($slug: String!, $tags: [String]) {
  query($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      frontmatter {
        title
        date
        author
        summary
        tags
      }
    }

    relatedPosts: allMarkdownRemark(
      limit: 3
      filter: {
        fields: { slug: { ne: $slug } }
        frontmatter: {
          type: { eq: "blog" }
          #tags: { in: $tags }
          published: { ne: false }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            date
            author
            summary
          }
        }
      }
    }
  }
`;

export default BlogPost;
